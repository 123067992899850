import React, { Fragment } from 'react';

import { CookieToggleRow } from './CookieToggleRow';

export const CookieToggleGroup = ({
    performanceChecked,
    marketingChecked,
    onPerformanceToggle,
    onMarketingToggle,
}: {
    performanceChecked: boolean
    onPerformanceToggle?: VoidFunction
    marketingChecked: boolean
    onMarketingToggle?: VoidFunction
}) => {
    const [isNecessaryCollapsed, setIsNecessaryCollapsed] = React.useState(false);
    const [isPerformanceCollapsed, setIsPerformanceCollapsed] = React.useState(false);
    const [isMarketingCollapsed, setIsMarketingCollapsed] = React.useState(false);

    const rowMeta: React.ComponentProps<typeof CookieToggleRow>[] = [
        {
            title: 'Strictly necessary',
            description: 'These cookies are essential for our website to function correctly. They help with page navigation, secure logins, and access to secure areas of the site.',
            locked: true,
            collapsibleData: [
                {
                    cookieName: 'Crisp',
                    cookieDescription: 'These cookies store the status of the chat',
                    privacyPolicy: 'https://crisp.chat/en/privacy/',
                },
                {
                    cookieName: 'Hubspot',
                    cookieDescription: "The HubSpot cookies are set by HubSpot's CDN provider because of their rate limiting policies and for bot protection.",
                    privacyPolicy: 'https://legal.hubspot.com/privacy-policy',
                },
                {
                    cookieName: 'Stripe',
                    cookieDescription: 'Used to process payments for our website',
                    privacyPolicy: 'https://stripe.com/en-ca/privacy',
                },
            ],
            onCollapse: () => setIsNecessaryCollapsed(!isNecessaryCollapsed),
            isVisible: isNecessaryCollapsed,
        },
        {
            title: 'Performance',
            description: 'These cookies help us improve our website by collecting information on how you use it. They help us understand user behavior to optimize our site.',
            checked: performanceChecked,
            onToggle: onPerformanceToggle,
            collapsibleData: [
                {
                    cookieName: 'Mixpanel',
                    cookieDescription: 'Mixpanel cookies track user behavior and interactions on the website. These cookies allow us to analyze user journeys, engagement metrics, and app/website usage patterns. They store anonymized data to help us improve functionality, user experience, and overall performance.',
                    privacyPolicy: 'https://mixpanel.com/legal/privacy-policy/',
                },
                {
                    cookieName: 'Baremetrics',
                    cookieDescription: 'Baremetrics cookies are used for financial and subscription analytics. They track user interaction data to provide insights on subscription trends, churn rates, and financial health. These cookies help us optimize business strategies without collecting personally identifiable information.',
                    privacyPolicy: 'https://baremetrics.com/privacy',
                },
                {
                    cookieName: 'Google analytics',
                    cookieDescription: 'Google Analytics cookies gather anonymized data about website traffic, user behavior, and session durations. This data helps us analyze trends, track website performance, and improve our content based on user interactions. All information is aggregated and anonymized to ensure compliance with privacy regulations.',
                    privacyPolicy: 'https://policies.google.com/privacy',
                },
            ],
            onCollapse: () => setIsPerformanceCollapsed(!isPerformanceCollapsed),
            isVisible: isPerformanceCollapsed,
        },
        {
            title: 'Marketing',
            description: 'These cookies are used to track effectiveness of our promotional campaigns and to deliver content that is more relevant to you, both within our service and in future communications.',
            checked: marketingChecked,
            onToggle: onMarketingToggle,
            collapsibleData: [
                {
                    cookieName: 'Hubspot',
                    cookieDescription: 'HubSpot cookies store marketing, lead generation, and visitor interaction data. These cookies help manage customer relationships, track marketing efforts, and improve user experiences by remembering previous interactions and personalizing future visits.',
                    privacyPolicy: 'https://legal.hubspot.com/privacy-policy',
                },
                {
                    cookieName: 'Google Ads',
                    cookieDescription: 'Google Ads cookies track conversions and user interactions with advertisements. They help us measure the effectiveness of ad campaigns and personalize ads based on user behavior.',
                    privacyPolicy: 'https://policies.google.com/privacy',
                },
            ],
            onCollapse: () => setIsMarketingCollapsed(!isMarketingCollapsed),
            isVisible: isMarketingCollapsed,
        },
    ];

    return (
        <div className='cookie-toggle-group d-flex flex-column'>
            {rowMeta.map(({
                title,
                description,
                locked,
                checked,
                onToggle,
                collapsibleData,
                isVisible,
                onCollapse,
            }) => (
                <Fragment key={title}>
                    <CookieToggleRow
                        key={title}
                        title={title}
                        description={description}
                        locked={locked}
                        checked={checked}
                        onToggle={onToggle}
                        collapsibleData={collapsibleData}
                        isVisible={isVisible}
                        onCollapse={onCollapse}
                    />
                </Fragment>
            ))}
        </div>
    );
};
